<template>
  <div class="chart--container__title">
    <h6 class="margin margin-tiny margin-bottom report-text-bold">
      {{ options.title }}
    </h6>
    <p class="margin margin-none margin-bottom report-text">
      {{ options.subtitle }}
    </p>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      val: this.value,
    };
  },
};

</script>

<style lang="scss" scoped>
  .report-text {

    font-size: 0.875rem;
    font-weight: 400;

    &-bold {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

</style>
