<template>
  <div class="chart-legend__container flex">
    <div
      v-for="(legend, key) in legends"
      :key="key"
      class="item"
    >
      <div
        class="legend__dot"
        :style="`background-color:${legend.color};`"
      />
      <h6 class="small-regular">
        {{ legend.title }}
      </h6>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    legends: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

};

</script>

<style lang="scss" scoped>

  .chart-legend__container {
    justify-content: center;
    padding: 1.5rem;
    .item {
      padding: 0 1rem;
      display: flex;
      align-items: center;

      h6 {
        margin-bottom: 0;
      }

      .legend__dot {
        width: 1rem;
        height: 1rem;
        margin-right: 0.6rem;
        border-radius: 50%;
        background-color: white;
      }

      .legend__icon {
        height: 2rem;
        width: 2rem;
      }
    }

  }

</style>
