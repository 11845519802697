/* eslint-disable class-methods-use-this */
/* eslint-disable key-spacing */
/* eslint-disable no-unused-vars */
import ExtendedDate from '@/classes/extended.date.class';
import ReportModel from '@/singletons/report.state.singleton';

import {
  randomInt, deepCopy, msToHrs, toFixedNumber, calculateWorkingHours,
} from '@/functions/helper.functions';
import OPTIONS_TEMPLATES from './chart.options.constants';

export const CHART_TYPES = {
  AVERAGE_USAGE_WEEK: 'week-average-usage',
  AVERAGE_USAGE_MONTH: 'month-average-usage',

  TOP_UTILIZATION_WEEK: 'week-top-utilization',
  TOP_UTILIZATION_MONTH: 'month-top-utilization',

  TOP_BOOKING_WEEK: 'week-top-booking',
  TOP_BOOKING_MONTH: 'month-top-booking',
};

class ChartController {
  getChart(chartType) {
    switch (chartType) {
      case CHART_TYPES.AVERAGE_USAGE_WEEK:
      case CHART_TYPES.AVERAGE_USAGE_MONTH: {
        return ChartController.getWeekAverageChart();
      }
      case CHART_TYPES.TOP_UTILIZATION_WEEK:
      case CHART_TYPES.TOP_UTILIZATION_MONTH: {
        return ChartController.getUtilizationChart();
      }
      case CHART_TYPES.TOP_BOOKING_WEEK:
      case CHART_TYPES.TOP_BOOKING_MONTH: {
        return ChartController.getBookingChart();
      }
      default: console.warn('No chart for type: ', chartType);
    }

    return null;
  }

  static getUtilizationChart() {
    const options = deepCopy(OPTIONS_TEMPLATES.TOP_UTILIZATION);

    const year = ReportModel.selectedYear.toString();
    const month = ReportModel.selectedMonth.toString();
    if (ReportModel.selectedPeriod === 'MONTH') {
      console.log(calculateWorkingHours(year, month));
      options.completeCapacityValue = calculateWorkingHours(year, month);
    } else if (ReportModel.selectedPeriod === 'WEEK') {
      options.completeCapacityValue = 40;
    }

    const data = [];
    const xAxis = [];
    for (let i = 0; i < 5; i += 1) {
      // eslint-disable-next-line no-continue
      if (!ReportModel.reportData.toputilized || ReportModel.reportData.toputilized.length <= i) continue;
      xAxis.push(ReportModel.reportData.toputilized[i].zonename);
      const u = Number(ReportModel.reportData.toputilized[i].utilized_minutes / 60).toFixed(2);

      data.push(u);
    }

    options.bars.data = data;
    options.xAxis.values = xAxis;

    return options;
  }

  static getBookingChart() {
    const options = deepCopy(OPTIONS_TEMPLATES.TOP_BOOKING);

    const year = ReportModel.selectedYear.toString();
    const month = ReportModel.selectedWeek.toString();
    if (ReportModel.selectedPeriod === 'MONTH') {
      options.completeCapacityValue = calculateWorkingHours(year, month);
    } else if (ReportModel.selectedPeriod === 'WEEK') {
      options.completeCapacityValue = 40;
    }

    const data = [];
    const xAxis = [];
    for (let i = 0; i < 5; i += 1) {
      // eslint-disable-next-line no-continue
      if (!ReportModel.reportData.topbooked || ReportModel.reportData.topbooked.length <= i) continue;
      xAxis.push(ReportModel.reportData.topbooked[i].zonename);
      const u = Number(ReportModel.reportData.topbooked[i].booked_minutes / 60).toFixed(2);

      data.push(u);
    }

    options.bars.data = data;
    options.xAxis.values = xAxis;

    return options;
  }

  static getWeekAverageChart() {
    const options = deepCopy(OPTIONS_TEMPLATES.AVERAGE_USAGE);

    const year = ReportModel.selectedYear.toString();
    const month = ReportModel.selectedWeek.toString();
    let factor = 1;
    if (ReportModel.selectedPeriod === 'MONTH') {
      factor = 40;
    } else if (ReportModel.selectedPeriod === 'WEEK') {
      factor = 8;
    }
    const data = [];
    // eslint-disable-next-line prefer-const
    let highVal = 5;
    for (let i = 1; i <= 5; i += 1) {
      // eslint-disable-next-line no-continue
      if (!ReportModel.reportData || Object.keys(ReportModel.reportData.utilization).includes(i)) continue;
      const day = ReportModel.reportData.utilization[i];
      const b = Number((((day.booked_minutes / day.total_minutes)) * 8).toFixed(2));
      const u = Number(((day.utilized_minutes / day.total_minutes) * 8).toFixed(2));
      console.log('B:', b, '\n', 'U', u);
      data.push({ booking: b, utilization: u });
    }

    let dataLen = 0;
    data.forEach((it) => {
      dataLen += 1;
      if (dataLen <= options.xAxis.values.length) {
        const dataRow = [it.booking, it.utilization];
        options.bars.data.push(dataRow);
      }
    });
    options.averageLines[0].overrideAverage = Number((ReportModel.reportData?.total_booked / ReportModel.reportData?.total_minutes)).toFixed(3);
    options.averageLines[1].overrideAverage = Number((ReportModel.reportData?.total_utilized / ReportModel.reportData?.total_minutes)).toFixed(3);

    return options;
  }
}

export default new ChartController();
