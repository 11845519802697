<!-- eslint-disable no-continue -->
<template>
  <div class="full-screen">
    <div
      v-if="ReportModel.filterState > 0"
      class="container"
      :style="{display: 'flex'}"
    >
      <Loader />
    </div>
    <div
      v-else
      class="container"
    >
      <div
        v-if="isInDevelop"
        class="btn-data"
        @click="toggleData=!toggleData"
      >
        <img
          src="/icons/dev.svg"
        >
      </div>
      <div class="padder">
        <!-- HEADER -->
        <div class="report-header b-bottom end">
          <div class="column">
            <h3>{{ currentPage?.displayName }}</h3>
            <span class="report-text-bold">{{ companyName }}</span>
          </div>
          <div class="column text-right">
            <span
              v-if="ReportModel.reportData?.week"
              class="report-text-bold"
            >Week {{ ReportModel.reportData?.week }} ({{ ReportModel.reportData?.startdate?.toString().substr(0, 10) }} - {{ moment(ReportModel.reportData?.enddate).subtract(1,'days').format('YYYY-MM-DD') }})</span>
            <span
              v-else-if="ReportModel.reportData?.month"
              class="report-text-bold"
            >{{ moment().month(ReportModel.reportData?.month - 1).format("MMM") }} ({{ ReportModel.reportData?.startdate?.toString().substr(0, 10) }} - {{ moment(ReportModel.reportData?.enddate).subtract(1,'days').format('YYYY-MM-DD') }})</span>
            <div>Total working hours: {{ Math.round(parseInt(ReportModel.reportData?.query.company_utilizationminutes) / 60) }} h</div>
          </div>
        </div>
        <div class="report-header b-bottom strech">
          <div class="column b-right w25">
            Location<br>
            <span
              v-if="ReportModel.selectedFloors.length > 1"
              class="report-text-bold"
            >
              Multiple ({{ ReportModel.selectedFloors.length }})
            </span>
            <span
              v-else
              class="report-text-bold"
            >
              Multiple ({{ ReportModel.selectedFloors.length }}) <!-- TODO: Show zone name -->
            </span>
          </div>
          <div class="column b-right w25">
            Resources<br>
            <span
              v-if="ReportModel.selectedZones.length === 0"
              class="report-text-bold"
            >
              All
            </span>
            <span
              v-else
              class="report-text-bold"
            >
              Multiple ({{ ReportModel.selectedZones.length }}) <!-- TODO: Show zone name -->
            </span>
          </div>
          <div class="column">
            Equipment<br>
            <span class="report-text-bold">{{ ReportModel.reportData?.equipment.join(', ') }}</span>
          </div>
        </div>
        <div
          v-if="currentPage?.name === 'week-average-usage'
          || currentPage?.name === 'month-average-usage'"
          class="report-header average-data"
        >
          <div class="column text">
            <p class="margin margin-tiny margin-bottom report-text-bold">
              Resource usage overview
            </p>
            <p class="margin margin-none report-text">
              Percentage of average booking and utilization
              hours during the time period
            </p>
          </div>
          <div

            class="averages"
          >
            <div>
              <AverageCard
                average-title="Booking average"
                average-icon="system_rep_booking"
                :average-percentage="averageBookingPercentage"
              />
            </div>
            <div>
              <AverageCard
                average-title="Utilization average"
                average-icon="system_utilization"
                :is-booking="false"
                :average-percentage="averageUtilizationPercentage"
              />
            </div>
          </div>
        </div>
        <!-- CHARTS -->
        <Chart
          v-if="reportOptions"
          class="chartbox"
          :options="reportOptions"
        />
      </div>
      <!-- DEBUG DATA -->
      <pre v-if="toggleData">
          Page: {{ currentPage }}
          {{ ReportModel.reportData }}
      </pre>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'chartist/dist/index.css';
import ReportService from '@/Services/Report/report.service';
import ReportModel from '@/singletons/report.state.singleton';
import CompanyService from '@/Services/Company/company.service';
import Chart from '@/components/chart/chart.container.vue';
import ChartController from './chart.controller';
import Loader from '../loading/components/loader.vue';
import AverageCard from './components/average.card.vue';

export default {
  name: 'ReportView',
  components: {
    Loader,
    AverageCard,
    Chart,
  },
  data() {
    return {
      ReportModel,
      moment,
      report: null,
      currentPage: null,
      toggleData: false,
      companyName: null,
      reportState: 0,
      timer: null,
      reportOptions: null,
      isInDevelop: process.env.VUE_APP_MODE.includes('development'),
    };
  },
  computed: {
    averageBookingPercentage() {
      return Number(((ReportModel.reportData?.total_booked / ReportModel.reportData?.total_minutes) * 100).toFixed(1));
    },
    averageUtilizationPercentage() {
      return Number(((ReportModel.reportData?.total_utilized / ReportModel.reportData?.total_minutes) * 100).toFixed(1));
    },
  },
  watch: {
    $route() {
      const { page } = this.$route.params;
      if (!this.currentPage || (page && typeof page !== 'undefined')) {
        ReportService.setPage(page);
        (async () => {
          this.currentPage = await ReportService.getActivePage();
        })();
      } else if (this.currentPage && page !== this.currentPage?.name) {
        this.$router.replace(`/report/${this.currentPage?.name}`);
      }
      this.loadChart();
    },
  },

  async mounted() {
    /** Use Current page (param) if exists */
    await ReportService.embedReport();
    this.companyName = await CompanyService.getRealmName();

    this.currentPage = await ReportService.getActivePage();
    const { page } = this.$route.params;
    if (this.currentPage && page !== this.currentPage.name) {
      this.$router.replace(`/report/${this.currentPage.name}`);
    }
    this.timer = setInterval(() => {
      if (this.reportState !== ReportModel.filterState) {
        this.reportState = ReportModel.filterState;
        if (this.reportState === 0) this.loadChart();
      }
    }, 100);
  },

  beforeDestroy() {
  },

  destroyed() {
    clearInterval(this.timer);
    ReportService.cleanup();
  },
  methods: {
    loadChart() {
      setTimeout(() => {
        this.reportOptions = ChartController.getChart(this.currentPage.name);
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.full-screen {
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: scroll;
}

.container {
  height: 100%;
  width: 100%;
  font-size: 0.8rem;
}

.container .padder {
  height:100%;
  padding: 2rem 1rem;
}

.btn-data {
  float: right;
  margin: 10px;
  cursor: pointer;
}
.btn-data:hover {
  opacity: 0.5;
}
.btn-data img {
  width: 16px;
  height: 16px;
}

.report-text {

    font-size: 0.875rem;
    font-weight: 400;

  &-bold {
    font-size: 0.875rem;
    font-weight: 600;
  }
}

.report-header {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
}

.report-header-info {
 p {
  margin: 0;
 }
}

.report-header.b-bottom {
  border-bottom: 1px solid #000;
}
.report-header.end {
  align-items: end;
}
.report-header.strech {
  align-items: stretch;
}
.report-header .row {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.report-header .column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.report-header .text-right {
  text-align: right;
}
.report-header .b-right {
  border-right: 1px solid #000;
  margin-right: 10px;
}
.report-header .w25 {
  flex-grow: 0.4;
}

.report-header.average-data {
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  .text {
    max-width: 18rem;
  }
  .averages {
    display: flex;
    gap: 2rem;
  }
}

.chartbox {
  height: calc(100% - 300px);
  width: 100%;
}
.ct-bar {
  stroke-width: 20px !important;
}

</style>
