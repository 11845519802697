<template>
  <div class="chart-bar--column">
    <!-- BAR CONTAINER -->
    <div
      :id="idProp"
      class="chart-bar--item__container"
    >
      <template
        v-for="(value, index) in options.values"
      >
        <div
          :id="`bar-${index}-${idProp}`"
          :key="index"
        >
          <div
            v-if="options.showPercentage && value > 0"
            class="chart--bar-item__mark"
          >
            <p class="chart-bar--percentage">
              {{ Number((value / options.completeCapacityValue) * 100).toFixed(1) }}%
            </p>
          </div>
          <div class="chart-bar--text__container" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    idProp: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      barElements: [],
      containerHeight: 0,
    };
  },

  watch: {
    options: function a() {
      this.setElements();
    },
  },

  mounted() {
    this.setContainer();
    this.setBars();
  },

  methods: {
    async setElements() {
      await this.$nextTick();
      this.setContainer();
      this.setBars();
    },

    getStyleHeightFromValue(value, max = this.options.maxHeight) {
      return (value / max) * 100;
    },

    setContainer() {
      const container = document.querySelector(`#${this.idProp}`);
      this.containerHeight = this.getStyleHeightFromValue(this.options.height);
      container.setAttribute('style', `height: ${this.containerHeight}%`);
    },

    setBars() {
      const { height, colors, values } = this.options;
      const w = (1 / values.length) * 100;
      for (let i = values.length - 1; i >= 0; i -= 1) {
        const barHeight = this.getStyleHeightFromValue(values[i], height);
        const newBar = document.querySelector(`#bar-${i}-${this.idProp}`);
        newBar.setAttribute('style', `height: ${barHeight}%; width: ${w}%; background-color: ${colors[i]};`);
        this.setBarLabels(newBar, i, barHeight, values[i]);
      }
    },

    setBarLabels(barElement, barIndex, height, value) {
      const textContainer = barElement.querySelector('.chart-bar--text__container');
      this.removeChildElements(textContainer);

      if (this.containerHeight < 5 || value <= 0) return;

      textContainer.classList.remove('padding-none');
      textContainer.classList.add('padding', 'padding-top', 'padding-small');

      const {
        values, unitFormat, textColors,
      } = this.options;
      let { toggleValue } = this.options;

      if (typeof toggleValue === 'undefined') toggleValue = null;

      let str1;
      if (toggleValue === null) {
        str1 = `${Number(values[barIndex]).toFixed(1)} ${unitFormat}`;
      } else if (toggleValue) str1 = `${height.toFixed(0)}%`;
      else if (!toggleValue) str1 = `${values[barIndex]} ${unitFormat}`;

      const firstEl = this.createH6Label(str1, `color: ${textColors[barIndex]};`);

      firstEl.classList.add('mini-semi');

      if (toggleValue === null) {
        textContainer.appendChild(firstEl);
      } else textContainer.appendChild(firstEl);
    },

    removeChildElements(element) {
      let child = element.lastElementChild;
      while (child) {
        element.removeChild(child);
        child = element.lastElementChild;
      }
    },

    createH6Label(text, style = null) {
      const el = document.createElement('h6');
      el.classList.add('bar--item__label');
      if (style) el.setAttribute('style', `${style}`);
      el.innerHTML = text;
      return el;
    },
  },

};

</script>

<style lang="scss">

    .mini-semi {
      font-size: 0.8125rem;
    }

    .chart-bar--percentage {
     margin: 0;
     overflow-y: hidden;
      text-overflow: wrap;
    }

    .chart-bar--column {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      flex-direction: column;
    }

    .chart-bar--item__container   {
      display: flex;
      // flex-direction: column;
      width: 6rem;
      align-items: flex-end;
      transition: height 0.5s ease;
    }

    .chart--bar-item__mark {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: -1.3rem;
    }

    .bar--item__label {
      color: white;
      max-width: 85%;

      margin-bottom: 0;
      margin-left: auto;
      margin-right: auto;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      text-overflow: wrap;
    }

    .chart-bar--text__container {
      text-align: center;
    }

</style>
