import i18n from '@/i18n';

const AVERAGE_USAGE = {
  title: 'Booking and utilization hours',
  subtitle: 'Booking and utilization hours and percentage comparison by day',
  yAxis: {
    steps: 5,
    unitFormat: ' h',
    showUnit: true,
  },
  y2Axis: {
    showUnit: true,
    unitFormat: ' %',
  },
  xAxis: {
    suffix: '',
    values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
  },
  x2: {
    suffix: '',
    values: [],
  },
  bars: {
    unitFormat: ' h',
    showPercentage: true,
    showComparison: true,
    titles: ['Booking', 'Utilization'],
    colors: ['#159648', '#BCECC2'],
    textColors: ['#FFFFFF', '#12823E'],
    data: [],
  },
  buildLegendsFromBars: false,
  legends: [
    {
      title: 'Booking data',
      color: '#10773e',
    },
    {
      title: 'Booking average',
      color: '#f09d4f',
    },
    {
      title: 'Utilization',
      color: '#BCECC2',
    },
    {
      title: 'Utilization average',
      color: '#0E4378',
    },
  ],
  averageLines: [
    {
      color: '#ED5106',
    },
    {
      color: '#0E4378',
    },
  ],
  completeCapacityValue: 8,
};

const TOP_UTILIZATION = {
  yAxis: {
    steps: 5,
    unitFormat: ' h',
    showUnit: true,
  },
  y2Axis: {
    showUnit: true,
    unitFormat: ' %',
  },
  xAxis: {
    suffix: '',
    values: [],
  },
  x2: {
    suffix: '',
    values: [],
  },
  bars: {
    unitFormat: ' h',
    showPercentage: true,
    showComparison: true,
    titles: ['Utilization'],
    colors: ['#BCECC2'],
    textColors: ['#000'],
    data: [],
  },
  showLegend: false,
  completeCapacityValue: 5 * 8,
};

const TOP_BOOKING = {
  yAxis: {
    steps: 5,
    unitFormat: ' h',
    showUnit: true,
  },
  y2Axis: {
    showUnit: true,
    unitFormat: ' %',
  },
  xAxis: {
    suffix: '',
    values: [],
  },
  x2: {
    suffix: '',
    values: [],
  },
  bars: {
    unitFormat: ' h',
    showPercentage: true,
    showComparison: true,
    titles: ['Booking'],
    colors: ['#159648'],
    textColors: ['#FFF'],
    data: [],
  },
  showLegend: false,
  completeCapacityValue: 5 * 8,
};

const OPTIONS_TEMPLATES = {
  AVERAGE_USAGE,
  TOP_UTILIZATION,
  TOP_BOOKING,
};

Object.freeze(AVERAGE_USAGE, TOP_UTILIZATION, TOP_BOOKING);

export default OPTIONS_TEMPLATES;
