<template>
  <div
    class="chart--average__holder"
    :style="`bottom: ${normalizedAveragePercent}%;`"
  >
    <div
      class="chart--average__line"
      :style="`border: 1px solid ${options.color};`"
    >
      <h6
        class="chart--average__label small-regular"
        :style="`color: ${options.color};`"
      >
        {{ options.overrideAverage ? Number(options.overrideAverage * 100).toFixed(1) : Number(options.trueAverage * 100).toFixed(1) }}%
      </h6>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  computed: {
    normalizedAveragePercent() {
      return this.options.overrideAverage ? this.options.normalizedOverrideAverage * 100 : this.options.normalizedAverage * 100;
    },
  },
};

</script>

<style lang="scss" scoped>

  .chart--average__holder {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: bottom 0.5s ease;

    .chart--average__line {
      height: 2px;
      width: 100%;

      .chart--average__label {
        position: absolute;
        right: 0;
        width: 0;
        margin-right: -0.5rem;
        overflow-wrap: normal;
        transform: translate(0, -50%);
      }
    }
  }

</style>
