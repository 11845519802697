<template>
  <div class="average-container">
    <div class="main-content">
      <div class="average-icon">
        <RoundResourceIcon
          :src="`/icons/${averageIcon}.svg`"
          :type="isBooking ? 'average booking' : 'average utilization'"
        />
      </div>
      <div class="average-information">
        <h6> {{ averageTitle }}</h6>
        <h3 :class="`percentage ${isBooking ? 'booking' : 'utilization'}`">
          {{ averagePercentage }}%
        </h3>
      </div>
    </div>
    <!-- TODO: Add this later -->
    <div
      v-if="false"
      class="bottom-content"
    >
      <div class="trend-precentage">
        <svg-icon
          v-if="trendPositive"
          src="/icons/system_trending_up.svg"
        />
        <svg-icon
          v-else
          src="/icons/system_trending_down.svg"
        />
        <p> {{ trendPercentage }}</p>
      </div>
      <p class="trend-text">
        {{ trendText }}
      </p>
    </div>
  </div>
</template>
<script>
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';

export default {
  components: {
    RoundResourceIcon,
  },
  props: {
    averageIcon: {
      type: String,
      required: true,
    },
    averageTitle: {
      type: String,
      required: true,
    },
    averagePercentage: {
      type: Number,
      required: true,
    },
    isBooking: {
      type: Boolean,
      required: false,
      default: true,
    },

    trendPercentage: {
      type: Number,
      required: false,
    },
    trendText: {
      type: String,
      required: false,
    },
    trendPositive: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.average-container {
  display: flex;
  border-radius: 0.85rem;
  border: 1px #D9D9D9 solid;
  padding: 1.125rem;
  .main-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: auto;
    .average-icon {
      margin: auto;
    }
    .average-information {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin: auto 0;
      align-content: flex-start;
      gap: 0.125rem;
      * {
        margin: 0;
      }
      .percentage {
        font-weight: 600;
        &.booking {
          color: #ED5106;
        }
        &.utilization {
          color: #0E4378;
        }
      }
    }
  }

}

</style>
