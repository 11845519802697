<template>
  <div
    class="chart--container"
  >
    <!-- TITLE / TOGGLE -->
    <div class="chart--info__container">
      <ChartTitle
        class="margin margin-medium2 margin-bottom"
        :options="titleOptions"
      />
      <!-- LEGEND -->
      <Legend
        v-if="legends.length"
        :legends="legends"
      />
    </div>

    <!-- CHART CONTAINER -->
    <div class="chart--content">
      <!-- Y-AXIS -->
      <div class="y-axis -left">
        <div
          v-for="(item, index) in yValues"
          :key="index"
          class="y-axis--item__container"
        >
          <div
            class="y-axis--item"
          >
            {{ item.value }}{{ item.showUnit ? options.yAxis.unitFormat : '' }}
          </div>
        </div>
      </div>
      <!-- CHART -->
      <div
        class="chart--lines__holder"
      >
        <template v-for="(averageOption, key) of averageOptions">
          <ChartAverageLine
            v-if="averageOption.show"
            :key="key"
            :options="averageOption"
          />
        </template>
        <div class="chart--bars__container">
          <ChartBar
            v-for="(value, index) in barsOptions"
            :key="'A' + index"
            :options="value"
            :id-prop="`chart-bar${index}`"
          />
        </div>
        <div
          v-for="(item, index) in yValues"
          :key="'B' + index"
          class="chart--line__container"
        >
          <div
            class="chart--line"
          />
        </div>
      </div>
      <!-- RIGHT SIDE Y-AXIS -->
      <div class="y-axis -right">
        <div
          v-for="(item, index) in y2Values"
          :key="'Y2' + index"
          class="y-axis--item__container"
        >
          <div
            class="y-axis--item"
          >
            {{ item.value }}{{ item.showUnit ? options.y2Axis.unitFormat : '' }}
          </div>
        </div>
      </div>
    </div>
    <!-- X-AXIS -->
    <div class="x-axis">
      <div
        v-for="(value, index) in options.xAxis.values"
        :key="'D' + index"
        class="x-axis--item__container overflow-hidden"
      >
        <h6 class="elipsis small-regular">
          {{ value }} <sup>{{ options.xAxis.suffix }}</sup>
        </h6>
        <h6
          v-if="x2Length"
          class="elipsis small-regular"
        >
          {{ options.x2.values[index] }}<sup>{{ options.x2.suffix }}</sup>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import Legend from './chart.legend.vue';
import ChartBar from './chart.bar.vue';
import ChartAverageLine from './chart.average.line.vue';
import ChartTitle from './chart.title.vue';

export default {
  components: {
    Legend,
    ChartBar,
    ChartAverageLine,
    ChartTitle,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      optionsValidated: true,
      barUnitToggle: null,
      maximumChartValue: 0,
      yValues: [],
      y2Values: [],
      legends: [],
      barsOptions: [],
      averageOptions: [],
      titleOptions: {},
    };
  },
  computed: {
    averageChartValues() {
      const barsValues = [];

      // Collect all the values from the bars
      for (let i = 0; i < this.barsOptions.length; i += 1) {
        barsValues.push(this.barsOptions[i].values);
      }

      const averages = [];
      const innerArrayLength = barsValues[0].length;

      // Calculate the average for each index
      for (let i = 0; i < innerArrayLength; i += 1) {
        let sum = 0;
        for (let j = 0; j < barsValues.length; j += 1) {
          sum += barsValues[j][i];
          averages[i] = { true: 0, normalized: 0 };
          averages[i].true = ((sum / barsValues.length) / this.options.completeCapacityValue);
          averages[i].normalized = ((sum / barsValues.length) / this.maximumChartValue);
        }
      }

      return averages;
    },
    y2() {
      return this.options?.y2?.slice().reverse() || [];
    },
    x2Length() {
      return this.options?.x2?.values?.length || 0;
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.buildData();
      },
    },
  },

  created() {
    this.buildData();
  },

  methods: {
    buildData() {
      this.buildLegend();
      this.buildYAxis();
      this.buildY2Axis();
      this.buildBars();
      this.buildAverage();
      this.buildTitle();
      console.log('REBUILDING');
    },
    buildAverage() {
      this.averageOptions = [];
      if (!this.options.averageLines) return;
      const averageOptions = [];
      let i = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const al of this.options.averageLines) {
        averageOptions.push({
          color: al.color,
          completeCapacityValue: this.options.completeCapacityValue,
          trueAverage: this.averageChartValues[i].true,
          normalizedAverage: this.averageChartValues[i].normalized,
          overrideAverage: al.overrideAverage ? al.overrideAverage : null,
          normalizedOverrideAverage: al.overrideAverage ? al.overrideAverage * (this.options.completeCapacityValue / this.maximumChartValue) : null,
          numberOfBars: this.options.bars.length,
          label: al.label,
          show: true,
        });
        this.averageOptions = averageOptions;
        i += 1;
      }
    },
    buildTitle() {
      this.titleOptions = {};
      this.titleOptions = {
        title: this.options.title,
        subtitle: this.options.subtitle,
      };
    },
    buildLegend() {
      this.legends = [];
      // Use options legends if exist
      if (this.options.legends && this.options.legends.length) {
        this.legends = this.options.legends;
        return;
      }

      // Else if buildLegendsFromBars is true create legends
      if (!this.options.buildLegendsFromBars) return;
      const { titles, colors } = this.options.bars;
      const legends = [];
      for (let i = 0; i < titles.length; i += 1) {
        const leg = {
          title: titles[i],
          color: colors[i],
        };
        legends.push(leg);
      }
    },

    buildBars() {
      this.barsOptions = [];
      const {
        data, unitFormat, colors, marks, showComparison, showPercentage, textColors,
      } = this.options.bars;

      const { completeCapacityValue } = this.options;

      for (let i = 0; i < data.length; i += 1) {
        const values = data[i];
        // eslint-disable-next-line no-loop-func
        const heights = Array.isArray(values) ? values : [values];
        const item = {
          colors,
          values: heights,
          height: Math.max(...heights),
          maxHeight: this.maximumChartValue,
          completeCapacityValue,
          toggleValue: this.options?.barSettings?.toggle,
          showComparison,
          showPercentage,
          unitFormat,
          mark: null,
          textColors,
        };

        if (typeof data[i].markIndex === 'number') {
          item.mark = marks[data[i].markIndex];
        }
        this.barsOptions.push(item);
      }
    },

    buildYAxis() {
      this.yValues = [];
      this.maximumChartValue = 0;
      const { showUnit, steps: desiredSteps } = this.options.yAxis;
      let max = this.options.bars.data.reduce((acc, v) => {
        const values = Array.isArray(v) ? v : [v];
        return Math.max(acc, Math.max(...values));
      }, 0);

      console.log(max);

      // Apply a margin
      max = Math.ceil(max * 1.1);
      this.maximumChartValue = max;

      // Calculate the number of steps and the dynamic step size
      const dynamicStepSize = max / desiredSteps;

      // Build the yValues array with the adjusted step size
      this.yValues = Array.from({ length: desiredSteps + 1 }, (_, index) => ({
        value: Number((index * dynamicStepSize).toFixed(1)),
        showUnit: showUnit || index === 0,
      })).reverse();
    },

    buildY2Axis() {
      this.y2Values = [];
      if (!this.options.y2Axis) return;

      const fullCapacityValue = this.options.completeCapacityValue;
      const maxActualValue = this.maximumChartValue; // This is the same max value as used in the Y1 axis
      const maxPercentage = (maxActualValue / fullCapacityValue) * 100;
      const desiredSteps = this.options.yAxis.steps; // Number of steps should be the same as for Y1 axis

      // Calculate the step size for the Y2 axis based on the max percentage
      const stepSizePercentage = maxPercentage / desiredSteps;

      this.y2Values = Array.from({ length: desiredSteps + 1 }, (_, index) => ({
        value: Number((index * stepSizePercentage).toFixed(0)),
        showUnit: this.options.y2Axis.showUnit || index === 0,
      })).reverse();
    },
  },
};

</script>

<style lang="scss" scoped>

  $y-axis-width: 6rem;

  .chart--container {
    display: flex;
    flex-direction: column;
  }

  .chart--info__container {
    height: 9rem;
  }

  .chart--content {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
  }

  .y-axis {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;
    width: $y-axis-width;

    &.-left {
      align-items: flex-start;
    }

    &.-right {
      align-items: flex-end;
    }
  }

  .y-axis--item__container, .chart--line__container {
    display: flex;
    align-items: center;

    &:not(.chart--line__container) {
      line-height: 0;
    }
  }

  .chart--lines__holder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .chart--line {
      flex-grow: 1;
      height: 1px;
      background-color: var(--border-color);
    }
  }

  .x-axis {
    display: flex;
    justify-content: space-around;
    margin: 0 $y-axis-width;
  }

  .x-axis--item__container {
    width: 6rem;
    text-align: center;
    h6 {
      margin-bottom: 0px;
      margin-top: 1px;
    }
  }

  .chart--bars__container {
    display: flex;
    position: absolute;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: flex-end;
  }

  sup {
    vertical-align: -2px;
  }

</style>
